<template>
  <layout-landing>
    <div class="landing register">
      <header class="landing__header">
        <div class="landing__logo-container">
          <img
            alt="Logo Sayl Connect"
            class="landing__logo"
            :src="logo"
          />
        </div>

        <div class="landing__locale-container">
          <locale-select class="landing__locale" />
        </div>
      </header>

      <div class="landing__body">
        <!-- Branding -->
        <div class="landing__branding">
          <h1 class="title">{{ $t('conn3ct-wallet.register_form_title') }}</h1>
          <div class="description">
            <p v-html="$t('conn3ct-wallet.register_form_description')"></p>

            <a
              class="landing__cta"
              @click="showWalletDescription = true"
            >{{ $t('conn3ct-wallet.wallet_description_cta') }}</a>
          </div>
        </div>

        <!-- Form -->
        <div class="landing__form">
          <div
            class="landing__error"
            v-if="!$basil.isNil(error)"
          >{{ error }}</div>

          <forms-phone
            v-if="countries"
            v-model="phone"
            @country-changed="handleCountryChange"
            class="landing__input"
            :country-id="country"
            :errors="fErrors['phone_number']"
            icon-post="phone"
            :options="countries"
            :placeholder="$t('conn3ct-wallet.phone_placeholder')"
            :disabled="phoneIsValid"
            :hint="!phoneIsValid ? $t('conn3ct-wallet.phone_number_format_hint') : null"
          >{{ $t('conn3ct-wallet.phone_lbl') }}</forms-phone>

          <forms-input
            class="landing__input"
            :errors="fErrors['password']"
            :hint="$t('conn3ct-wallet.register_pswd_policy_hint')"
            :placeholder="$t('conn3ct-wallet.password_placeholder')"
            :type="showPassword ? 'text' : 'password'"
            @input="onRemoveError('password')"
            v-model="password"
          >
            {{ $t('conn3ct-wallet.password_lbl') }}
            <template #suffix>
              <div
                @click="onPassword"
                class="forms-control__post -is-clickable"
              >
                <ui-icon :glyph="showPassword ? 'visible' : 'hidden'" />
              </div>
            </template>
          </forms-input>

          <forms-input
            class="landing__input"
            :placeholder="$t('conn3ct-wallet.password_confirmation_placeholder')"
            :type="showPasswordConfirmation ? 'text' : 'password'"
            v-model="confirmation"
          >
            {{ $t('conn3ct-wallet.password_confirm_lbl') }}
            <template #suffix>
              <div
                @click="onPasswordConfirmation"
                class="forms-control__post -is-clickable"
              >
                <ui-icon :glyph="showPasswordConfirmation ? 'visible' : 'hidden'" />
              </div>
            </template>
          </forms-input>

          <actions-button
            @click="onConfirm"
            :appearance="$pepper.Appearance.PRIMARY"
            class="landing__action"
            :disabled="loading || !isFilled"
            :loading="loading"
          >{{ $t('conn3ct-wallet.register_action') }}</actions-button>
        </div>

        <div class="landing__signature">
          {{ $t('conn3ct-wallet.register_have_account_label') }}
          <a
            @click="$router.push({ name: 'sayl-connect_landing-login' })"
            class="landing__signature-action"
            :size="$pepper.Size.S"
          >{{ $t('conn3ct-wallet.register_sign_in_action') }}</a>
        </div>
      </div>
    </div>

    <popins-modal
      @close="showWalletDescription = false"
      position="top-center"
      :size="$pepper.Size.S"
      :visible="showWalletDescription"
    >
      <template v-slot:header>
        <h4 class="landing__about-title">{{ $t('conn3ct-wallet.wallet_description_title') }}</h4>
      </template>

      <div
        class="landing__about-description"
        v-html="$t('conn3ct-wallet.wallet_description_content')"
      ></div>

      <template v-slot:footer>
        <actions-button @click="showWalletDescription = false">{{ $t('conn3ct-wallet.wallet_description_close') }}</actions-button>
      </template>
    </popins-modal>
  </layout-landing>
</template>

<script>
import { mapState } from 'vuex'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'

import LayoutLanding from '@/layouts/landing.vue'
import Header from '@/components/navigation/header.vue'
import FormsPhone from '@/components/forms/input-phone.vue'
import LocaleSelect from '@/components/actions/locale-select.vue'

export default {
  name: 'SaylConn3ctRegisterPhone',

  components: {
    LayoutLanding,
    Header,
    FormsPhone,
    LocaleSelect
  },

  data() {
    return {
      confirmation: '',
      done: false,
      phone: '',
      error: null,
      fErrors: {},
      password: '',
      loading: false,
      showPassword: false,
      showPasswordConfirmation: false,
      walletDescriptionShowned: false,
      countries: null,
      country: null,
      countryCode: 'BE',
      phoneIsValid: false,
      showWalletDescription: false
    }
  },

  computed: {
    ...mapState({
      navigationParams: state => state.bootstrap.navigationParams,
      bootstrap: state=> state.bootstrap.data
    }),

    isFilled() {
      return [
        this.$basil.isNil(this.phone),
        this.$basil.isEmpty(this.phone),
        this.$basil.isNil(this.password),
        this.$basil.isEmpty(this.password),
        this.$basil.isNil(this.confirmation),
        this.$basil.isEmpty(this.confirmation),
      ].filter(f => f === true).length === 0
    },

    selectedCountryCode() {
      let country = this.countries.find(c => c.id === this.country)

      return this.$basil.get(country, 'isoCode2')
    },

    selectedCountryCallingCode() {
      let country = this.countries.find(c => c.id === this.country)
      console.log(country)

      return this.$basil.get(country, 'callingCode')
    },

    merchantLogo() {
      return this.bootstrap?.brand?.logo
    },

    logo() {
      if(this.merchantLogo) {
        return this.translate(this.merchantLogo.url)
      }

      return this.$root.getSrc('statics/images/sayl-logo-color.svg')
    }
  },

  methods: {
    async init() {
      try {
        const user = await this.$user.getUser()

        this.setLocale(user.lang)
        this.$router.push({ name: 'sayl-connect_dashboard-dashboard' }).catch(() => {})
      } catch(e) {
        this.$wallet.reset()
        this.$dashboard.reset()
        await this.loadCountries()

        const navigationParamsString = window.sessionStorage.getItem('navigationParams')

        if(!navigationParamsString) {
          return
        }

        const { phoneNumber } = JSON.parse(navigationParamsString)

        this.phone = phoneNumber?.replace(' ', '+')

        this.initPhone()

        window.sessionStorage.removeItem('navigationParams')
      }
    },

    initPhone() {
      try {
        let { nationalNumber, country } = parsePhoneNumber(this.phone)
        this.phone = nationalNumber
        this.countryCode = country
        this.phoneIsValid = true
      } catch(e) {}
    },

    async loadCountries() {
      try {
        let list = await this.$user.countries()

        this.countries = list.filter(c => c.callingCode)

        let geoCode = this.countryCode ?? this.$basil.get(this.bootstrap, 'geolocation_country_code')

        if(!this.$basil.isNil(geoCode)) {
          let country = this.countries.find(c => c.isoCode2 === geoCode)
          if(!this.$basil.isNil(country)) {
            this.country = country.id
          }
        }
      } catch(e) {
        $console.error(e)
      }
    },

    handleCountryChange(id) {
      this.country = id
    },

    async onConfirm() {
      this.loading = true
      this.errors = null
      this.fErrors = {}
      let phone = this.phone.replace(this.selectedCountryCallingCode, '')

      phone = `${this.selectedCountryCallingCode}${phone}`

      if(!phone || !isValidPhoneNumber(phone, this.selectedCountryCode)) {
        this.fErrors = { phone_number: [this.$t('conn3ct-wallet.register_invalid_phone_number')] }
        this.loading = false
        return
      }

      let payload = {
        phone,
        password: this.password,
        confirmation: this.confirmation,
      }

      try {
        await this.$user.register(payload)

        this.done = true
        return this.$router.push({ name: 'sayl-connect_onboarding-confirm-phone', params: { phone } })
      } catch(e) {
        if(this.$basil.get(e, 'status', 500) === 422) {
          let keys = Object.keys(e._items)
          keys.forEach(k => {
            if(typeof e._items[k] === 'string') {
              let v = e._items[k].split('.').join('_')
              this.fErrors[k] = [this.$t('conn3ct-wallet.register_' + v)]
            } else {
              this.fErrors[k] = e._items[k].map(err => this.$t(`conn3ct-wallet.${err}`))
            }
          })
        }
      } finally {
        setTimeout(() => this.loading = false, 2000)
      }
    },

    onPassword() {
      this.showPassword = !this.showPassword
    },

    onPasswordConfirmation() {
      this.showPasswordConfirmation = !this.showPasswordConfirmation
    },

    onPressEnter(e) {
      if(e.keyCode === 13 && this.isFilled && !this.loading) {
        this.onConfirm()
      }
    },

    onRemoveError(name) {
      delete this.fErrors[name]
    },
  },

  mounted() {
    this.init()

    window.addEventListener('keypress', this.onPressEnter)
  },

  beforeDestroy() {
    window.removeEventListener('keypress', this.onPressEnter)
  }
}
</script>
